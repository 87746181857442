<template>
    <div>
        <v-tabs v-model="actualTab">
            <v-tab class="text-no-wrap">
                <span>Validations en attente</span>
                <NotificationBadge v-if="toValidateUnavs" :label="toValidateUnavs.length" />
            </v-tab>

            <v-tab class="text-no-wrap">
                <span>Annulations en attente</span>
                <NotificationBadge v-if="cancelPendingUnavs" :label="cancelPendingUnavs.length" />
            </v-tab>

            <v-tab>Historique</v-tab>
        </v-tabs>

        <div class="ma-3">
            <Spinner v-if="isSpinnerDisplayed" />

            <v-row no-gutters align="center" justify="start">
                <ValidationsDataTable
                    v-if="actualTab === 0"
                    :isTableLoading="isTableLoading"
                    :items="toValidateUnavs"
                    :unavStatus="STATUT_INDISPO.AVALIDER"
                    @unavs-update="loadData()"
                    @show-spinner="showSpinner()"
                    @hide-spinner="hideSpinner()"
                />

                <ValidationsDataTable
                    v-else-if="actualTab === 1"
                    :isTableLoading="isTableLoading"
                    :items="cancelPendingUnavs"
                    :unavStatus="STATUT_INDISPO.ATTENTEANNULATION"
                    @unavs-update="loadData()"
                    @show-spinner="showSpinner()"
                    @hide-spinner="hideSpinner()"
                />

                <HistoryDataTable
                    v-else-if="actualTab === 2"
                    :isTableLoading="isTableLoading"
                    :items="historic"
                    @show-spinner="showSpinner()"
                    @hide-spinner="hideSpinner()"
                />
            </v-row>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
import { mapMutations } from 'vuex';
import validationsApi from '@/api/validations';
import { STATUT_INDISPO } from '@/constants/unavailabilities';
import { API_MAX_LIMIT } from '@/constants/shared';
import Spinner from '@/components/Spinner.vue';
import ValidationsDataTable from '@/components/ValidationsDataTable.vue';
import HistoryDataTable from '@/components/HistoryDataTable.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';

const fileName = 'MyValidationsPage.vue';

export default {
    name: 'MyValidationsPage',

    components: {
        NotificationBadge,
        Spinner,
        ValidationsDataTable,
        HistoryDataTable,
    },

    data() {
        return {
            isTableLoading: false,
            isSpinnerDisplayed: false,
            actualTab: 0,

            STATUT_INDISPO,

            timer: null,
            interval: 30000,

            items: undefined,
            historic: [],
        };
    },

    computed: {
        toValidateUnavs() {
            return this.items
                ? this.items.filter((item) => item.statut === STATUT_INDISPO.AVALIDER)
                : [];
        },
        cancelPendingUnavs() {
            return this.items
                ? this.items.filter((item) => item.statut === STATUT_INDISPO.ATTENTEANNULATION)
                : [];
        },
    },

    async created() {
        this.showSpinner();
        await this.loadData();
        this.hideSpinner();
    },

    mounted() {
        this.timer = setInterval(() => {
            this.loadData();
        }, this.interval);
    },

    watch: {
        actualTab(newVal) {
            if (newVal === 2) {
                this.loadHistoricData();
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    methods: {
        ...mapMutations(['showErrorToast']),

        async loadHistoricData() {
            try {
                this.showLoading();
                this.showSpinner();
                let data = await validationsApi.getToValidateHistory(0, 50);
                this.historic = data.items;
                this.hideSpinner();

                while (data.hasMore) {
                    const offset = data.offset + data.limit;
                    data = await validationsApi.getToValidateHistory(offset, API_MAX_LIMIT);
                    this.historic = this.historic.concat(data.items);
                }
                this.hideLoading();
            } catch (error) {
                this.hideLoading();
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },
        async loadData() {
            try {
                this.showLoading();
                let data = await validationsApi.getUnavsToValidate(0, 50);
                this.items = data.items;

                while (data.hasMore) {
                    const offset = data.offset + data.limit;
                    data = await validationsApi.getUnavsToValidate(offset, API_MAX_LIMIT);
                    this.items = this.items.concat(data.items);
                }
                this.hideLoading();
            } catch (error) {
                this.hideLoading();
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },

        showLoading() {
            this.isTableLoading = true;
        },
        hideLoading() {
            this.isTableLoading = false;
        },
        showSpinner() {
            this.isSpinnerDisplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDisplayed = false;
        },
    },
};
</script>
